const leftOptionsObject = (intensityBandsRange: any) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    layout: {
      padding: 0,
    },
    scales: {
      y: {
        min: 0,
        max: intensityBandsRange,
        stepSize: 5,
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      x: {
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
  };
};
export default leftOptionsObject;
