import { Button } from "../../../shared/Button";
import { Text } from "../styled/Text";
export interface TestStartProps {
  startConfirmed: () => void;
}

export const TestStart = (props: TestStartProps) => {
  return (
    <div className="flex-col h-full flex px-4">
      <h2 className="font-bold text-2xl medium:text-3xl tallAndWide:text-4xl mb-4">
        Online hearing assessment
      </h2>
      <Text>
        Please make sure you are in a quiet place with zero distractions, as
        this test will require your full attention.
        <br></br>
        Place the red headphone over your right ear and the blue one over your
        left ear.
        <br></br>
        <span className="mt-4 inline-block">
          When you are ready, press ‘Start’.
        </span>
      </Text>

      <div className="px-2 pt-2 b-1 justify-self-end self-end mt-auto">
        <Button
          text="Start"
          onClick={props.startConfirmed}
          dataTest="start-test"
        />
      </div>
    </div>
  );
};
