import React, { useState, useContext } from "react";
import { Button } from "../../../shared/Button";
import { Ear } from "../../../shared/Ear";
import { AudioChannel } from "../../../shared/models/audio-channel";
import { Trial } from "../../../shared/models/trial";
import { LoggingService } from "../../../shared/services/logging-service";
import { AudioService } from "../../services/audio-service";
import { NumberPad } from "../number-pad/NumberPad";

import CalibrationContext from "../../../../contexts/CalibrationContext";
import { Text } from "../styled/Text";
import { useSetAtom } from "jotai";
import { userInputsAtom } from "../../../../atoms/atoms";

export interface RunTrialProps {
  trial: Trial;
  postTrial: (patiantResponse: number) => void;
  onStageComplete: () => void;
  loading: boolean;
}

export const RunTrial = (props: RunTrialProps) => {
  const [soundPlayed, setSoundPlayed] = useState<boolean>(false);
  const [soundPlaying, setSoundPlaying] = useState<boolean>(false);
  const [selectedNumber, setSelectedNumber] = useState<number | null>();

  const calibration = useContext<any>(CalibrationContext);
  const setUserInputs = useSetAtom(userInputsAtom);

  const onStart = () => {
    setSoundPlaying(true);
    let obj = {
      name: "Start playing sound",
      properties: {
        component: "RunTrial",
        message: "Start playing sound",
      },
    };

    LoggingService.InfoLogger(obj);
  };

  const onEnd = () => {
    setSoundPlaying(false);
    setSoundPlayed(true);
    let obj = {
      name: "End playing sound",
      properties: {
        component: "RunTrial",
        message: "End playing sound",
      },
    };

    LoggingService.InfoLogger(obj);
  };

  const playSounds = () => {
    setSoundPlayed(false);
    setSelectedNumber(null);

    if (process.env.REACT_APP_CI_TEST_ENV_FLAG) {
      setSoundPlayed(true);
      return;
    }

    //calibration here
    AudioService.playTrial(props.trial, onStart, onEnd, calibration[0]);
  };

  const nextTrial = () => {
    setUserInputs((prev) => [...prev, selectedNumber!]);
    props.postTrial(selectedNumber!);
    setSoundPlayed(false);
    setSelectedNumber(null);
    let obj = {
      name: "Next Trial",
      properties: {
        component: "RunTrial",
        message: "Go to next trial",
      },
    };

    LoggingService.InfoLogger(obj);
  };

  const playSoundBtnTest = soundPlayed ? "Retry" : "Play sounds";

  const channelLabel = () => {
    return props.trial.channel === AudioChannel.right ? "RIGHT" : "LEFT";
  };

  const message = () => {
    if (!soundPlayed && !soundPlaying) {
      return (
        <React.Fragment>
          <Text className="mb-1 medium:mb-2">
            You are going to hear several tones being presented to your
            <span className="font-bold"> {channelLabel()} </span> ear. Count the
            number of times you hear a sound, no matter how quiet it may be.
            Click 'Play sound' when you are ready.
          </Text>
        </React.Fragment>
      );
    }

    if (soundPlaying) {
      return (
        <React.Fragment>
          <Text>Test in progress...</Text>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Text className="mb-1 medium:mb-2">
          Enter the number of sounds you heard and press next.
        </Text>
        <Text className="mb-1 medium:mb-2">
          If you are unsure of the number of sounds you can retry.
        </Text>
      </React.Fragment>
    );
  };

  const displaySelectedNumber = () => {
    if (
      (selectedNumber === null || selectedNumber === undefined) &&
      soundPlayed
    ) {
      let obj = {
        name: "Sound was played",
        properties: {
          component: "RunTrial",
          message: "Sound was played",
        },
      };

      LoggingService.InfoLogger(obj);
      return <Text className="font-semibold">Select number of sounds</Text>;
    }

    if (selectedNumber != null) {
      let obj = {
        name: "Number was selected",
        properties: {
          component: "RunTrial",
          message: "Number was selected",
        },
      };

      LoggingService.InfoLogger(obj);
      return (
        <Text className="font-semibold">
          You heard {selectedNumber} {selectedNumber === 1 ? "sound" : "sounds"}{" "}
        </Text>
      );
    }
  };

  const subTitle = () => {
    if (props.trial.channel === AudioChannel.right) {
      return "Hearing test";
    }

    return "Hearing test";
  };

  return (
    <React.Fragment>
      <Text className="font-bold mb-1 medium:mb-3 tallAndWide:mb-6">
        {subTitle()}, Stage {props.trial.channel}
      </Text>
      <div className="min-h-[15vh] tall:min-h-[10vh]">{message()}</div>

      <div className="tall:pt-4 tall:pb-2 flex flex-col justify-center w-full">
        <div className="mt-1 tall:mt-12">
          <Ear channel={props.trial.channel}></Ear>
        </div>
        <div className="justify-self-center self-center">
          <NumberPad
            disabled={!soundPlayed}
            onSelected={setSelectedNumber}
            selectedNumber={selectedNumber}
          />
        </div>

        <div className="text-xs tall:text-base tall:pt-4 tall:pb-2 flex justify-center h-8 mt-4">
          {displaySelectedNumber()}
        </div>
      </div>

      <div className="flex gap-2 justify-self-end self-end mt-auto">
        <Button
          text={playSoundBtnTest}
          onClick={playSounds}
          disabled={soundPlaying || props.loading}
          dataTest="play-sound"
        />
        <Button
          text="Next"
          onClick={nextTrial}
          disabled={
            selectedNumber == null ||
            selectedNumber === undefined ||
            soundPlaying ||
            props.loading
          }
          dataTest="next"
        />
      </div>
    </React.Fragment>
  );
};
