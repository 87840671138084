const resultTextObject = () => {
  return {
    resultText: [
      {
        summary: "Your hearing is normal",
        detail: "Good news! It looks like your hearing is fine.",
      },
      {
        summary: "Your hearing loss is mild",
        detail:
          "The results of the test suggest that you have only mild hearing loss. We recommend that you address this hearing loss however as, untreated, it can potentially impact your quality of life. Hearing usually deteriorates over time as well, so it’s important to address it as early as possible. ",
      },
      {
        summary: "You have moderate hearing loss",
        detail:
          "The results of the test suggest that you may have moderate hearing loss. We recommend that you address this hearing loss as soon as possible as, untreated, it can potentially impact your quality of life and may lead to other health issues. We recommend that you take a full audiological test with our Home Hearing Kit and consider using hearing aids. If you opted to receive a copy of the report by email please be sure to check your junk and spam folders in case it is sent there.",
      },
      {
        summary: "You have severe hearing loss",
        detail:
          "The results of the test suggest that you have considerable hearing loss. We recommend that you address this hearing loss as soon as possible as, untreated, hearing loss can potentially impact your quality of life and may lead to other health issues.",
      },
      {
        summary: "You have profound hearing loss",
        detail:
          "The results of the test suggest that you have considerable hearing loss. We recommend that you address this hearing loss as soon as possible as, untreated, hearing loss can potentially impact your quality of life and may lead to other health issues.",
      },
    ],
  };
};
export default resultTextObject;
