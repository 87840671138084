import React from "react";

type Props = {
  className?: string;
  htmlFor?: string;
};

export const Label: React.FC<Props> = ({ children, className, htmlFor }) => {
  return (
    <label
      className={`text-gray-700 text-sm medium:text-base tallAndWide:text-lg ${className}`}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
};
