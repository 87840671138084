import React, { useState } from "react";
import { Button } from "../../../shared/Button";
import { Ear } from "../../../shared/Ear";
import { AudioChannel } from "../../../shared/models/audio-channel";
import { Trial } from "../../../shared/models/trial";
import { LoggingService } from "../../../shared/services/logging-service";
import { AudioService } from "../../services/audio-service";
import { Text } from "../styled/Text";

export interface OrientationCheckProps {
  trial: Trial;
  onStageComplete: () => void;
  volumePercentage: number;
}

export const OrientationCheck = (props: OrientationCheckProps) => {
  const [soundPlayed, setSoundPlayed] = useState<boolean>(false);
  const [soundPlaying, setSoundPlaying] = useState<boolean>(false);

  const onStart = () => {
    setSoundPlaying(true);
    let obj = {
      name: "Sound on start",
      properties: {
        component: "OrientationCheck",
        message: "Sound started",
      },
    };

    LoggingService.InfoLogger(obj);
  };

  const onEnd = () => {
    setSoundPlaying(false);
    setSoundPlayed(true);
    let obj = {
      name: "Sound on end",
      properties: {
        component: "OrientationCheck",
        message: "Sound ended",
      },
    };

    LoggingService.InfoLogger(obj);
  };

  const handlePlaySound = () => {
    if (process.env.REACT_APP_CI_TEST_ENV_FLAG) {
      setSoundPlayed(true);
      return;
    }

    AudioService.playOrientationCheck(props.trial.channel!, onStart, onEnd);
    let obj = {
      name: "Orientation check",
      properties: {
        component: "OrientationCheck",
        message: "Play Orientation check",
      },
    };

    LoggingService.InfoLogger(obj);
  };

  const playSoundBtnTest = soundPlayed ? "Retry" : "Play sound";

  const channelLabel = () => {
    return props.trial.channel === AudioChannel.right ? "RIGHT" : "LEFT";
  };
  return (
    <React.Fragment>
      <h3 className="font-semibold text-sm medium:text-lg tallAndWide:text-xl mb-2">
        Pre-test check
      </h3>
      {!soundPlayed ? (
        <React.Fragment>
          <Text>
            We need to make sure you have the headphones on the correct way
            round.
          </Text>
          <Text>
            Please check if the headphones are correctly connected and set the
            volume at {props.volumePercentage}%{" "}
          </Text>
          <Text>
            Press 'Play Sound' when ready. You should hear the tone in your{" "}
            <span className="font-bold">{channelLabel()}</span> ear.
          </Text>
          <div className="mt-4 tall:mt-10">
            <Ear channel={props.trial.channel}></Ear>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Text>
            If you heard a sound in your{" "}
            <span className="font-bold">{channelLabel()}</span> ear please press
            'Next" to continue.
          </Text>
          <Text>
            If you did not hear any sound, please check the headphones are
            correctly connected and the volume is at {props.volumePercentage}%
            and try again.
          </Text>
          <Text>
            If you hear a sound in your <span className="font-bold">LEFT</span>{" "}
            ear, swap the orientation of the headphones and retry.
          </Text>
        </React.Fragment>
      )}
      <div className="flex gap-2 px-6 pt-4 pb-2 justify-self-end self-end mt-auto">
        <Button
          text={playSoundBtnTest}
          onClick={handlePlaySound}
          disabled={soundPlaying}
          dataTest="play-sound"
        />
        <Button
          text="Next"
          onClick={props.onStageComplete}
          disabled={!soundPlayed || soundPlaying}
          dataTest="next"
        />
      </div>
    </React.Fragment>
  );
};
