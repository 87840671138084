export interface NumberButtonProps {
  number: number;
  disabled?: boolean;
  onClick?: (number: number) => void;
  selected: boolean;
  dataTest?: string;
}

export const NumberButton = (props: NumberButtonProps) => {
  const base = `group items-center my-1 text-sm font-bold px-1 py-1 numberCircle
    medium:text-base medium:px-3 medium:py-2 medium:my-2
    tallAndWide:text-2xl
    focus:hoverBlue focus:text-blue focus:drop-shadow-md focus:ring-indigo-500 `;
  const active =
    "hover:bg-blue-700 transition hover:hoverBlue duration-150 hover:text-blue duration-100";
  const disabled = "opacity-50 cursor-not-allowed";
  const unselected = "text-white bg-blue ";
  const selected = "text-blue drop-shadow-md ring-indigo-500 bg-gray-100";

  const handleClick = (e: any) => {
    if (props.onClick && !props.disabled) {
      props.onClick(props.number);
    }
  };

  return (
    <button
      disabled={props.disabled}
      className={`${base} ${props.disabled ? disabled : active} ${
        props.selected ? selected : unselected
      }`}
      onClick={handleClick}
      data-test={props.dataTest}
    >
      {props.number}
    </button>
  );
};
