const intensityBandsObject = () => {
  return {
    bands: [
      {
        range: 25, // 30
        name: "No Loss",
        colour: "#5C9D1AFF",
        index: 0,
      },
      {
        range: 15, // 20
        name: "Mild",
        colour: "#D5D538FF",
        index: 1,
      },
      {
        range: 30,
        name: "Moderate",
        colour: "#D8913AFF",
        index: 2,
      },
      {
        range: 20,
        name: "Severe",
        colour: "#DE3636FF",
        index: 3,
      },
      {
        // This figure may need adjusting. - Any results higher than this band are to be taken as 'Max' of this band.
        range: 30,
        name: "Profound",
        colour: "#9F0F0FFF",
        index: 4,
      },
    ],
  };
};
export default intensityBandsObject;
