import { NumberButton } from "./NumberButton";

export interface NumberPadProps {
  onSelected: (number: number) => void;
  disabled?: boolean;
  selectedNumber: number | null | undefined;
}

export const NumberPad = (props: NumberPadProps) => {
  return (
    <div className="grid grid-cols-7 gap-2 lg:flex">
      <NumberButton
        disabled={props.disabled}
        number={0}
        onClick={(num) => props.onSelected(num)}
        selected={props.selectedNumber === 0}
        dataTest="number-pad-0"
      />
      <NumberButton
        disabled={props.disabled}
        number={1}
        onClick={(num) => props.onSelected(num)}
        selected={props.selectedNumber === 1}
        dataTest="number-pad-1"
      />
      <NumberButton
        disabled={props.disabled}
        number={2}
        onClick={(num) => props.onSelected(num)}
        selected={props.selectedNumber === 2}
        dataTest="number-pad-2"
      />
      <NumberButton
        disabled={props.disabled}
        number={3}
        onClick={(num) => props.onSelected(num)}
        selected={props.selectedNumber === 3}
        dataTest="number-pad-3"
      />
      <NumberButton
        disabled={props.disabled}
        number={4}
        onClick={(num) => props.onSelected(num)}
        selected={props.selectedNumber === 4}
        dataTest="number-pad-4"
      />
      <NumberButton
        disabled={props.disabled}
        number={5}
        onClick={(num) => props.onSelected(num)}
        selected={props.selectedNumber === 5}
        dataTest="number-pad-5"
      />
      <NumberButton
        disabled={props.disabled}
        number={6}
        onClick={(num) => props.onSelected(num)}
        selected={props.selectedNumber === 6}
        dataTest="number-pad-6"
      />
    </div>
  );
};
