import { useContext, useEffect, useState } from "react";

import IframeContext from "../../../contexts/IframeContext";
import { setPath } from "../../../utility/setPath";
import { TestStage } from "../../shared/models/test-stage";
import { PageContainer } from "../../shared/PageContainer";

import { HearingTestService } from "../services/hearing-test-service";

import { HearingTestPanel } from "./HearingTestPanel";
import { PartyCodeContext } from "../../../contexts/PartyCodeContext";
import { useAtom, useAtomValue } from "jotai";
import { hearingTestAtom, userDetailsAtom } from "../../../atoms/atoms";
import { useNavigate } from "react-router-dom";

const volumePercentage = 100;

export const HearingTestDemo = (props: {}) => {
  const userDetails = useAtomValue(userDetailsAtom);
  const [hearingTestDetails, setHearingTestDetails] = useAtom(hearingTestAtom);
  const navigate = useNavigate();

  const isIframe = useContext(IframeContext);
  const [loading, setLoading] = useState<boolean>(false);

  const partyDetails = useContext(PartyCodeContext);

  //protect the route if there are no user data
  useEffect(() => {
    if (!userDetails.externalId) {
      navigate(setPath(`/occupational-health`, isIframe));
    }
  }, [userDetails.externalId, isIframe, navigate]);

  //Start test when all fildes are ready
  useEffect(() => {
    if (
      !hearingTestDetails.trial?.id &&
      partyDetails.slug &&
      userDetails.externalId &&
      hearingTestDetails.trial?.stage !== TestStage.complete
    ) {
      HearingTestService.checkPartyCode(partyDetails.slug)
        .then((partyExist) => {
          if (!partyExist) {
            navigate(
              setPath(`/${partyDetails.slug}/partyDoesNotExist`, isIframe)
            );
          }
        })
        .catch((error) => {
          navigate(
            setPath(`/${partyDetails.slug}/partyDoesNotExist`, isIframe)
          );
        });

      HearingTestService.checkTestCodeExist(userDetails.externalId)
        .then((textCodeExist) => {
          return HearingTestService.startNewTest(
            userDetails.externalId,
            "demo",
            userDetails.age,
            userDetails.gender,
            userDetails.fullName,
            userDetails.email,
            userDetails.userId
          );
        })
        .then((trial) => {
          setHearingTestDetails((prev) => ({ ...prev, trial }));
        })
        .catch((error) => {
          navigate(setPath(`/${partyDetails.slug}/serverError`, isIframe));
        });
    }
  }, [
    hearingTestDetails.trial?.id,
    isIframe,
    navigate,
    partyDetails.slug,
    setHearingTestDetails,
    userDetails.age,
    userDetails.email,
    userDetails.externalId,
    userDetails.fullName,
    userDetails.gender,
    userDetails.userId,
    hearingTestDetails.trial?.stage,
  ]);

  //This function should be used to go for result
  useEffect(() => {
    if (
      hearingTestDetails.trial?.testId &&
      hearingTestDetails.trial?.stage === TestStage.complete
    ) {
      navigate(
        setPath(
          `/result/${hearingTestDetails.trial.testId}/${userDetails.externalId}/${userDetails.userId}/${userDetails.allUserData}`,
          isIframe
        )
      );
    }
  }, [
    hearingTestDetails.trial?.stage,
    partyDetails.slug,
    hearingTestDetails.trial.testId,
    navigate,
    userDetails.externalId,
    userDetails.userId,
    userDetails.allUserData,
    isIframe,
  ]);

  const postTrial = (response: number) => {
    setLoading(true);

    HearingTestService.saveTrialAndContinue(
      hearingTestDetails.trial.id,
      response
    )
      .then((newTrial) => {
        setHearingTestDetails((prev) => ({ ...prev, trial: newTrial }));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <PageContainer>
      <HearingTestPanel
        loading={loading}
        trial={hearingTestDetails.trial}
        postTrial={postTrial}
        volumePercentage={volumePercentage}
      />
    </PageContainer>
  );
};
