import { useRef } from "react";

import { exportDiagnostics } from "./exportDiagnostics";
import { exportAudiogram } from "./exportAudiogram";
import { buildDiagnostics } from "./buildDiagnostics";

export interface TestToolProps {
  category: any;
}

export const TestTool = (props: TestToolProps) => {
  const diagnosticsRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="self-end justify-self-end m-auto">
        <button
          className="p-5 mt-5"
          onClick={() => {
            exportAudiogram("test.png");
          }}
          data-test="export-audiogram"
        >
          TEST AUDIOGRAM EXPORT
        </button>
      </div>
      <div className="self-end justify-self-end m-auto">
        <button
          className="p-5 mt-5"
          onClick={() => {
            if (diagnosticsRef.current != null) {
              exportDiagnostics(diagnosticsRef.current);
            }
          }}
          data-test="export-diagnostics"
        >
          EXPORT DIAGNOSTIC INFORMATION
        </button>
      </div>

      <div
        className="W-[100%] h-0 overflow-hidden"
        ref={diagnosticsRef}
        id="diagnosticsMarker"
      >
        {buildDiagnostics(props.category!)}
      </div>
    </>
  );
};
