export interface LatusSessionVerificationResult {
  code?: string;
  message?: string;
  data?: {
    status: number;
  };
  authHeader?: string;
  firebaseHeader?: string;
  email?: string;
  gender?: GenderTypes;
  age?: number;
  fullName?: string;
  userId?: string; // to connect results together
}

export const GenderEnum = {
  male: 1,
  female: 2,
} as const;

export type GenderTypes = keyof typeof GenderEnum;
