import React from "react";

type Props = {
  className?: string;
  id?: string;
  name?: string;
  onChange?: (event: any) => any;
  value?: string | number;
  dataTest?: string;
};

export const Select: React.FC<Props> = ({
  children,
  dataTest,
  className,
  ...props
}) => {
  return (
    <select
      className={`border border-slate-200 p-1 text-sm rounded-md drop-shadow-sm w-full ${className}
        tallAndSlim:py-2
        medium:py-3 medium:text-base
        tallAndWide:py-4
      `}
      data-test={dataTest}
      {...props}
    >
      {children}
    </select>
  );
};
