import { useContext } from "react";
import logo from "../../images/logo.svg";
import {
  PartyCodeContext,
  PartyCodeDispatchContext,
} from "../../contexts/PartyCodeContext";
import { Button } from "../shared/Button";
import { EnabledTestsDispatchContext } from "../../contexts/EnabledTestsContext";
import { useAtomValue, useSetAtom } from "jotai";
import { resetAllAtoms, userDetailsAtom } from "../../atoms/atoms";
import { Text } from "../hearing-test/components/styled/Text";
import { useNavigate } from "react-router-dom";

export const NavigationBar = () => {
  const partyDetails = useContext(PartyCodeContext);
  const clinicDetailsDispatch = useContext(PartyCodeDispatchContext);
  const testsDispatch = useContext(EnabledTestsDispatchContext);
  const resetAtoms = useSetAtom(resetAllAtoms);
  const userDetails = useAtomValue(userDetailsAtom);

  const navigate = useNavigate();

  const onClick = () => {
    sessionStorage.clear();
    resetAtoms(true);
    let navigateToDeepLink = false;
    if (partyDetails.slug === "latus") {
      navigateToDeepLink = true;
    }
    clinicDetailsDispatch({
      type: "setClinic",
      payload: { slug: "", name: "" },
    });
    testsDispatch({ type: "setTests", payload: {} });
    if (navigateToDeepLink) {
      const testId = userDetails.externalId;
      window.location.href =
        "https://yodhadatacollection.app.link/EUohp9obuDb?testid=" + testId;
    } else {
      navigate("/occupational-health");
    }
  };

  return (
    <div className="h-[10vh] tallAndSlim:h-[15vh] medium:h-[15vh] z-40 relative">
      {partyDetails.slug && (
        <aside
          className={`top-[2vh] right-[5vw]  flex flex-col
        wideAndShort:right-[2.5vw] wideAndShort:flex-row wideAndShort:gap-2 wideAndShort:items-center
        medium:right-[10vw] medium:flex-col
        tallAndWide:flex-row tallAndWide:items-center tallAndWide:gap-2
        justify-end absolute`}
        >
          <Text className="font-semibold inline-block">
            Clinic: {partyDetails.name}
          </Text>
          <div className="flex justify-end">
            <Button text="Logout" size="small" onClick={onClick} />
          </div>
        </aside>
      )}
      <div className="flex h-full justify-center">
        <img
          src={logo}
          alt="Logo"
          className="self-center max-h-8 px-4 medium:max-h-16 medium:px-8"
        ></img>
        {/* <span className="text-2xl text-blue font-bold px-4 self-center font-sans">Hearing Test</span> */}
      </div>
    </div>
  );
};
