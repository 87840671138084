import React, { useContext, useEffect } from "react";
import { PageContainer } from "../modules/shared/PageContainer";
import { Spinner } from "../modules/shared/Spinner";
import { EnabledTestsContext } from "../contexts/EnabledTestsContext";
import { useAtomValue } from "jotai";
import { userDetailsAtom, videoTestAtom } from "../atoms/atoms";
import IframeContext from "../contexts/IframeContext";
import { setPath } from "./setPath";
import { PartyCodeContext } from "../contexts/PartyCodeContext";
import { useNavigate } from "react-router-dom";

type Props = {};

export const Redirect = (props: Props) => {
  const tests = useContext(EnabledTestsContext);
  const userDetails = useAtomValue(userDetailsAtom);
  const partyDetails = useContext(PartyCodeContext);
  const videoTestResultAtom = useAtomValue(videoTestAtom);
  const isIframe = useContext(IframeContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userDetails.email) {
      if (partyDetails.slug === "demo") {
        navigate(setPath("/occupational-health/hearing-test", isIframe));
      } else if (
        tests.videoTestEnabled &&
        videoTestResultAtom.leftEarPictures.length === 0
      ) {
        navigate(setPath("/occupational-health/video-test", isIframe));
      } else if (tests.speechTestEnabled) {
        navigate(setPath("/occupational-health/speech-test", isIframe));
      } else if (tests.hearingTestEnabled) {
        navigate(setPath("/occupational-health/hearing-test", isIframe));
      } else {
        //TODO: add no tests configured route
        navigate(setPath("/occupational-health/not-found", isIframe));
      }
    } else navigate(setPath("/occupational-health", isIframe));
  }, [
    tests,
    userDetails,
    videoTestResultAtom,
    isIframe,
    navigate,
    partyDetails.slug,
  ]);

  return (
    <PageContainer>
      <Spinner />
    </PageContainer>
  );
};
