const audiogramLineDataObject = () => {
  return {
    labels: ["500", "1000", "2000", "3000", "4000", "6000", "8000"],
    datasets: [
      {
        label: "Left Ear",
        data: [1, 2, 3, 4, 5, 6, 7] as unknown[],
        fill: false,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 152, 235, 1.0)",
        tension: 0.1,
        pointStyle: "crossRot",
        pointRadius: 15,
        pointBorderColor: "rgb(53,162,235)",
        pointStyleWidth: 10,
      },
      {
        label: "Right Ear",
        data: [7, 6, 5, 4, 3, 2, 1] as number[],
        fill: false,
        borderColor: "rgb(255, 120, 120)",
        backgroundColor: "rgba(255, 120, 120, 0.0)",
        tension: 0.1,
        pointStyle: "circle",
        pointRadius: 15,
        pointBorderColor: "rgb(255,120,120)",
        pointStyleWidth: 10,
      },
    ],
  };
};
export default audiogramLineDataObject;
