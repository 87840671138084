import { HseV1CategorisationResult } from "../../shared/models/hse-v1-categorisation-result";
import intensityBandsObject from "../functions/intensityBandsObject";
import resultTextObject from "../functions/resultTextObject";

import parse from "html-react-parser";

const intensityBands = intensityBandsObject();
const resultText = resultTextObject();

export const reportOverallResult = (
  leftResult: HseV1CategorisationResult,
  rightResult: HseV1CategorisationResult
) => {
  if (leftResult && rightResult) {
    let leftResultAverage: number = calculateAverageResult(leftResult);
    let rightResultAverage: number = calculateAverageResult(rightResult);

    let leftBand = calculateCategoryBand(leftResultAverage);
    let rightBand = calculateCategoryBand(rightResultAverage);
    if (leftBand.index !== rightBand.index) {
      return (
        <div className="flex flex-raw space-x-2">
          <div className="w-[50%]">
            <div className="mt-6">
              <b>{parse(resultText.resultText[leftBand.index].summary)}</b>
            </div>
            <div className="my-6">
              {parse(resultText.resultText[leftBand.index].detail)}
            </div>
          </div>
          <div className="w-[50%]">
            <div className="mt-6">
              <b>{parse(resultText.resultText[rightBand.index].summary)}</b>
            </div>
            <div className="my-6">
              {parse(resultText.resultText[rightBand.index].detail)}
            </div>
          </div>
        </div>
      );
    } else {
      let band = overallResult(leftResult, rightResult);
      return (
        <div>
          <div className="my-6">
            <b>{parse(resultText.resultText[band.index].summary)}</b>
          </div>
          <div className="mb-6">
            {parse(resultText.resultText[band.index].detail)}
          </div>
        </div>
      );
    }
  }
};

const calculateAverageResult = (result: HseV1CategorisationResult) => {
  let total: number = 0;
  if (result.bandedIntensity) {
    result.bandedIntensity.forEach((intensity) => {
      total += intensity;
    });
    return total / result.bandedIntensity.length;
  } else {
    return 0;
  }
};

const calculateCategoryBand = (score: number) => {
  let runningTotal = score;
  let assignedBand = intensityBands.bands[intensityBands.bands.length - 1];
  let found = false;
  intensityBands.bands.forEach((band) => {
    if (runningTotal < band.range && found === false) {
      assignedBand = band;
      found = true;
    }
    runningTotal -= band.range;
  });
  return assignedBand;
};

const overallResult = (
  leftResult: HseV1CategorisationResult,
  rightResult: HseV1CategorisationResult
) => {
  // Calculate overall result.
  return calculateCategoryBand(
    (calculateAverageResult(leftResult) + calculateAverageResult(rightResult)) /
      2.0
  );
};
