import html2canvas from "html2canvas";
import { downloadImage } from "./downloadImage";

export const exportAudiogram = async (imageFileName: string) => {
  let element = document.getElementById("marker");

  if (element) {
    let canvasElement = element.querySelector("canvas");
    await html2canvas(element, {
      onclone: function (clonedDoc: any) {
        let ourElement = clonedDoc.getElementById("marker");
        if (ourElement != null) {
          ourElement.style.visibility = "visible";
        }
      },
    }).then((canvas: any) => {
      //const image = canvas.toDataURL("iamge/png", 1.0);
      if (canvasElement) {
        const image = canvasElement.toDataURL("image/png", 0.5);
        downloadImage(image, imageFileName);
      }
    });
  }
};
