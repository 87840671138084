import { useAtom } from "jotai";
import React, { useContext, useState } from "react";
import { userDetailsAtom } from "../../../atoms/atoms";
import CalibrationContext from "../../../contexts/CalibrationContext";
import { emailValidation } from "../../../helpers/emailValidation";
import defaultCalibration from "../../config/defaultCalibration";
import { PageContainer } from "../../shared/PageContainer";
import { PartyCodeContext } from "../../../contexts/PartyCodeContext";
import { setPath } from "../../../utility/setPath";
import IframeContext from "../../../contexts/IframeContext";
import {
  externalIdGenerator,
  userIdGenerator,
} from "../../shared/utility/idGenerators";
import { Label } from "./styled/Label";
import { Text } from "./styled/Text";
import { Input } from "./styled/Input";
import { Button } from "../../shared/Button";
import { Select } from "./styled/Select";
import { UserService } from "../services/user-service";
import { useNavigate } from "react-router-dom";

type Props = {};

export const UserDetailsInput = (props: Props) => {
  const [userDetails, setUserDetails] = useAtom(userDetailsAtom);
  const isIframe = useContext(IframeContext);
  const navigate = useNavigate();

  const [, setCalibration] = useContext<any>(CalibrationContext);

  const [emailError, setEmailError] = useState<string>("");
  const [ageError, setAgeError] = useState<string>("");
  const [genderError, setGenderError] = useState<string>("");
  const [fullNameError, setFullNameError] = useState<string>("");
  const [ageString, setAgeString] = useState<string>("");

  const partyDetails = useContext(PartyCodeContext);

  async function startTest(partySlug: string) {
    setEmailError("");
    setAgeError("");
    setGenderError("");
    setFullNameError("");

    if (emailValidation(userDetails.email) === null) {
      setEmailError("Please enter a valid email.");
    } else if (partySlug !== "demo" && !userDetails.fullName) {
      setFullNameError("Please enter your full name.");
    } else if (userDetails.age < 18) {
      setAgeError("Please enter a valid age.");
    } else {
      if (userDetails.gender === 1 || userDetails.gender === 2) {
        const allUserData =
          partySlug === "demo"
            ? btoa(
                userDetails.email +
                  "£" +
                  userDetails.gender +
                  "£" +
                  userDetails.age
              )
            : btoa(
                userDetails.fullName +
                  "£" +
                  userDetails.email +
                  "£" +
                  userDetails.gender +
                  "£" +
                  userDetails.age
              );

        // TODO: add company calibrations here / use default one as a fallback

        setCalibration(defaultCalibration().frequencies);

        let userIdData;
        if (partySlug.toLowerCase() !== "demo") {
          try {
            userIdData = await UserService.getNonIntegratedUserDetails(
              userDetails.email,
              partySlug
            );
          } catch (error) {
            console.error(error);
          }
        }

        let userId;

        if (userIdData?.userId) {
          userId = userIdData.userId;
        } else {
          userId = userIdGenerator(partySlug);

          if (partyDetails.name.toLowerCase() !== "demo") {
            try {
              await UserService.saveUserDetails(
                userDetails.email,
                partySlug,
                userId
              );
            } catch (error) {
              console.error(error);
            }
          }
        }

        setUserDetails({
          ...userDetails,
          externalId: externalIdGenerator(partySlug),
          userId,
          allUserData,
        });

        if (userDetails.email)
          navigate(setPath("/occupational-health/redirect", isIframe));
      } else {
        setGenderError("Please enter a valid gender.");
      }
    }
  }

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgeString(e.target.value);
    const age = e.target.value ? Number(e.target.value) : 0;
    setUserDetails({ ...userDetails, age });
  };

  return (
    <PageContainer>
      <form>
        <div
          className={
            partyDetails.slug === "demo" ? "grid" : "grid grid-cols-2 gap-2"
          }
        >
          <div className="mb-7 text-left">
            <Label
              htmlFor="demo-email"
              className="font-semibold mb-1 inline-block"
            >
              Email:
            </Label>
            <br />
            <Input
              type="email"
              className="border border-slate-200 p-4 rounded-md drop-shadow-sm w-full"
              id="demo-email"
              required
              onChange={(e) =>
                setUserDetails({ ...userDetails, email: e.target.value })
              }
              value={userDetails.email}
              dataTest="email"
            />
            {emailError && (
              <Text className="text-[#ff0000]" dataTest="email-error">
                {emailError}
              </Text>
            )}
          </div>
          {partyDetails.slug !== "demo" ? (
            <div className="mb-7 text-left">
              <Label
                htmlFor="demo-email"
                className="font-semibold mb-1 inline-block"
              >
                Full Name:
              </Label>
              <br />
              <Input
                type="text"
                className="border border-slate-200 p-4 rounded-md drop-shadow-sm w-full"
                id="demo-full-name"
                required
                onChange={(e) =>
                  setUserDetails({ ...userDetails, fullName: e.target.value })
                }
                value={userDetails.fullName}
                dataTest="full-name"
              />
              {fullNameError && (
                <Text className="text-[#ff0000]" dataTest="full-name-error">
                  {fullNameError}
                </Text>
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="grid grid-cols-2 gap-2">
          <div className="mb-7 text-left w-full">
            <Label
              htmlFor="demo-age"
              className="font-semibold mb-1 inline-block"
            >
              Age:
            </Label>
            <br />
            <Input
              type="number"
              className="border border-slate-200 p-4 rounded-md drop-shadow-sm uppercase appearance-none hover:appearance-none w-full mr-2"
              id="demo-age"
              name="age"
              onChange={(e) => {
                handleAgeChange(e);
              }}
              value={ageString}
              dataTest="age"
            />
            {ageError && (
              <Text className="text-[#ff0000]" dataTest="age-error">
                {ageError}
              </Text>
            )}
          </div>

          <div className="mb-7 text-left w-full">
            <Label
              htmlFor="demo-gender"
              className="font-semibold mb-1 inline-block"
            >
              Gender:
            </Label>
            <br />
            <Select
              id="demo-gender"
              onChange={(e) =>
                setUserDetails({
                  ...userDetails,
                  gender: Number(e.target.value),
                })
              }
              value={userDetails.gender}
              data-test="gender"
            >
              <option value="1">Male</option>
              <option value="2">Female</option>
            </Select>
            {genderError && (
              <Text className="text-[#ff0000]" dataTest="gender-error">
                {genderError}
              </Text>
            )}
          </div>
        </div>

        <Button
          onClick={() => startTest(partyDetails.slug)}
          type="button"
          dataTest="take-test"
          text="Take Test"
        />
      </form>
    </PageContainer>
  );
};
