import { atom } from "jotai";
import { Trial } from "../modules/shared/models/trial";

export const userDetailsAtom = atom({
  fullName: "",
  email: "",
  age: 0,
  gender: 1,
  userId: "",
  allUserData: "",
  externalId: "",
});

export const hearingTestAtom = atom({
  trial: {} as Trial,
});

export const videoTestAtom = atom({
  leftEarPictures: [] as string[],
  rightEarPictures: [] as string[],
});

export const cameraDetailsAtom = atom({
  cameraName: "" as string | undefined,
});

export const resetAllAtoms = atom(
  false, // default is not resetting
  (get, set, resetSignal) => {
    // the update function
    if (!resetSignal) return;

    // Reset each atom to its default value
    set(userDetailsAtom, {
      fullName: "",
      email: "",
      age: 0,
      gender: 1,
      userId: "",
      allUserData: "",
      externalId: "",
    });

    set(hearingTestAtom, {
      trial: {} as Trial,
    });

    set(videoTestAtom, {
      leftEarPictures: [] as string[],
      rightEarPictures: [] as string[],
    });

    set(cameraDetailsAtom, {
      cameraName: "" as string | undefined,
    });

    // reset the reset signal back to false
    set(resetAllAtoms, false);
  }
);

// User Inputs for Non organic test detection
export const userInputsAtom = atom<number[]>([]);
