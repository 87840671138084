import React from "react";

type Props = {
  type?: string;
  className?: string;
  id?: string;
  name?: string;
  onChange?: (event: any) => any;
  value?: string | number;
  dataTest?: string;
  required?: boolean;
};

export const Input: React.FC<Props> = ({
  type,
  className,
  dataTest,
  ...props
}) => {
  return (
    <input
      type={type}
      className={`border border-slate-200 text-sm p-1 rounded-md drop-shadow-sm uppercase appearance-none hover:appearance-none w-full mr-2 ${className}
      tallAndSlim:py-2
      medium:py-3 medium:text-base
      tallAndWide:py-4
      `}
      data-test={dataTest}
      {...props}
    />
  );
};
