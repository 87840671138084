import { axiosAdminApiProvider } from "../../state/app-state";
import { ClinicQueryResult } from "../../shared/models/ClinicDetails";
import axios from "axios";
import { LatusSessionVerificationResult } from "../../shared/models/Auth";

export interface AdminAPISaveResultsResponse {
  code: string;
  message: string;
}

export class AdminApi {
  public static saveResults = (
    categorisation: any,
    userData: any,
    clinic: any,
    imageBase64: any,
    userId: any
  ): Promise<AdminAPISaveResultsResponse> => {
    return axiosAdminApiProvider()
      .post<any>(
        `/api/v1/save-results`,
        { categorisation, userData, clinic, imageBase64, userId },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => response.data);
  };

  public static saveNonIntegratedResults = (
    categorisation: any,
    userData: any,
    clinic: any,
    imageBase64: any,
    userId: any,
    videoTest: any,
    userInputs: number[]
  ): Promise<AdminAPISaveResultsResponse> => {
    return axiosAdminApiProvider()
      .post<any>(
        `/api/v1/save-results`,
        {
          categorisation,
          userData,
          clinic,
          imageBase64,
          userId,
          videoTest,
          userInputs,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => response.data);
  };

  public static saveLatusResults = (
    categorisation: any,
    userData: any,
    clinic: any,
    imageBase64: any,
    userId: any,
    videoTest: any,
    userInputs: number[]
  ): Promise<AdminAPISaveResultsResponse> => {
    const firebaseHeader = sessionStorage.getItem("firebaseHeader");
    return axiosAdminApiProvider()
      .post<any>(
        `/api/v1/save-latus-results`,
        {
          categorisation,
          userData,
          clinic,
          imageBase64,
          userId,
          videoTest,
          firebaseHeader,
          userInputs,
        },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => response.data);
  };

  public static saveDemo = (
    categorisation: any,
    userData: any,
    imageBase64: any,
    userId: any
  ): Promise<AdminAPISaveResultsResponse> => {
    return axiosAdminApiProvider()
      .post<any>(
        `/api/v1/demo-process`,
        { categorisation, userData, imageBase64, userId },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => response.data);
  };

  public static payCredits = (
    clinicID: string,
    testId: string,
    externalId: string,
    userId: string,
    appointmentId: any
  ): Promise<object> => {
    return axiosAdminApiProvider()
      .post<object>(`/api/v1/update-credits`, {
        clinicID,
        testId,
        externalId,
        userId,
        appointmentId,
      })
      .then((response) => response.data);
  };

  public static getDeviceCalibration = (clinicID: string): Promise<any> => {
    return axiosAdminApiProvider()
      .get<any>(
        `/api/v1/calibration?company=${clinicID}`
        // retired: `/api/v1/get-calibration?company=${clinicID}&device=${device}&headphones=${headphones}`
      )
      .then((response) => response.data);
  };

  public static getCompanyByTestId = (
    companyTestId: string
  ): Promise<ClinicQueryResult> => {
    const encodedCompanyTestId = encodeURI(companyTestId);
    return axiosAdminApiProvider()
      .get<any>(`/api/v1/company/${encodedCompanyTestId}`, {
        withCredentials: true,
      })
      .then((response) => {
        const authToken = response.data ? response.data.authToken : null;
        if (authToken) {
          sessionStorage.setItem("authToken", authToken);

          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${authToken}`;
        }
        return response.data;
      });
  };

  public static getFirebaseAuthTokenBySessionId = (
    sessionId: string
  ): Promise<LatusSessionVerificationResult> => {
    return axiosAdminApiProvider()
      .get<LatusSessionVerificationResult>(
        `/api/v1/get-auth-header?session_id=${sessionId}`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        const authHeader = response.data?.authHeader;
        const firebaseHeader = response.data?.firebaseHeader;

        if (authHeader && firebaseHeader) {
          sessionStorage.setItem("authHeader", authHeader);
          sessionStorage.setItem("firebaseHeader", firebaseHeader);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${authHeader}`;
        }
        return response.data;
      });
  };

  public static logout = (): Promise<any> => {
    return axiosAdminApiProvider()
      .get<any>(`/api/v1/logout`, { withCredentials: true })
      .then((response) => response.data);
  };
}
