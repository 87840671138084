import { useContext, useEffect, useState } from "react";

import IframeContext from "../../../contexts/IframeContext";
import { setPath } from "../../../utility/setPath";
import { TestStage } from "../../shared/models/test-stage";
import { PageContainer } from "../../shared/PageContainer";

import { HearingTestService } from "../services/hearing-test-service";

import { HearingTestPanel } from "./HearingTestPanel";
import {
  PartyCodeContext,
  PartyCodeDispatchContext,
} from "../../../contexts/PartyCodeContext";
import { useAtom, useAtomValue } from "jotai";
import {
  hearingTestAtom,
  userDetailsAtom,
  videoTestAtom,
} from "../../../atoms/atoms";
import { AdminApi } from "../services/admin-api";
import {
  externalIdGenerator,
  userIdGenerator,
} from "../../shared/utility/idGenerators";
import defaultCalibration from "../../config/defaultCalibration";
import CalibrationContext from "../../../contexts/CalibrationContext";
import { GenderEnum } from "../../shared/models/Auth";
import { UserService } from "../services/user-service";
import { VideoTestNonIntegrated } from "./video-test/VideoTestNonIntegrated";
import { useNavigate, useParams } from "react-router-dom";

const volumePercentage = 100;

export const HearingTestLatus = (props: {}) => {
  const [userDetails, setUserDetails] = useAtom(userDetailsAtom);
  const partyDetails = useContext(PartyCodeContext);
  const partyDetailsDispatch = useContext(PartyCodeDispatchContext);
  const isIframe = useContext(IframeContext);
  const [, setCalibration] = useContext<any>(CalibrationContext);
  const navigate = useNavigate();
  const { sessionId } = useParams();

  // check auth token and set user details
  useEffect(() => {
    if (sessionId) {
      AdminApi.getFirebaseAuthTokenBySessionId(sessionId)
        .then((response) => {
          if (
            response?.authHeader &&
            response.age &&
            response.email &&
            response.gender &&
            response.fullName
          ) {
            const email = response.email;
            const age = response.age;
            const fullName = response.fullName;
            const gender =
              response.gender === "male" ? GenderEnum.male : GenderEnum.female;

            partyDetailsDispatch({
              type: "setClinic",
              payload: { slug: "latus", name: "Latus" },
            });
            setCalibration(defaultCalibration().frequencies);
            const allUserData = btoa(
              response.fullName +
                "£" +
                response.email +
                "£" +
                gender +
                "£" +
                response.age
            );

            const userId = response.userId ?? userIdGenerator("latus");

            console.log(response.userId);

            if (!response.userId) {
              UserService.saveUserDetails(response.email, "latus", userId);
            }

            setUserDetails((prev) => ({
              ...prev,
              fullName,
              age,
              email,
              gender,
              externalId: externalIdGenerator("latus"),
              userId,
              allUserData: allUserData,
            }));

            AdminApi.getDeviceCalibration("latus")
              .then((res) => {
                setCalibration(res.frequencies);
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            navigate(setPath(`/occupational-health`, isIframe));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      navigate(setPath(`/occupational-health`, isIframe));
    }
  }, [
    sessionId,
    isIframe,
    setUserDetails,
    partyDetailsDispatch,
    setCalibration,
    navigate,
  ]);

  const [hearingTestDetails, setHearingTestDetails] = useAtom(hearingTestAtom);

  const [loading, setLoading] = useState<boolean>(false);

  const [videoTestEnabled, setVideoTestEnabled] = useState<boolean>(false);
  const videoTestDetails = useAtomValue(videoTestAtom);

  useEffect(() => {
    if (userDetails.externalId) {
      setVideoTestEnabled(true);
    }
  }, [userDetails.externalId]);

  //Start test when all fields are ready
  useEffect(() => {
    if (
      !hearingTestDetails.trial?.id &&
      partyDetails.slug &&
      userDetails.externalId &&
      hearingTestDetails.trial?.stage !== TestStage.complete
    ) {
      HearingTestService.checkPartyCode(partyDetails.slug)
        .then((partyExists) => {
          if (!partyExists) {
            navigate(
              setPath(`/${partyDetails.slug}/partyDoesNotExist`, isIframe)
            );
          }
        })
        .catch((error) =>
          navigate(setPath(`/${partyDetails.slug}/partyDoesNotExist`, isIframe))
        );

      HearingTestService.checkTestCodeExist(userDetails.externalId)
        .then((testCodeExists) => {
          HearingTestService.startNewTest(
            userDetails.externalId,
            "latus",
            userDetails.age,
            userDetails.gender,
            userDetails.fullName,
            userDetails.email,
            userDetails.userId
          ).then((trial) =>
            setHearingTestDetails((prev) => ({ ...prev, trial }))
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [
    hearingTestDetails.trial?.id,
    hearingTestDetails.trial?.stage,
    isIframe,
    navigate,
    partyDetails.slug,
    setHearingTestDetails,
    userDetails.age,
    userDetails.email,
    userDetails.externalId,
    userDetails.fullName,
    userDetails.gender,
    userDetails.userId,
  ]);

  //This function should be used to go for result
  useEffect(() => {
    if (
      hearingTestDetails.trial?.testId &&
      hearingTestDetails.trial?.stage === TestStage.complete
    ) {
      navigate(
        setPath(
          `/result/latus/${hearingTestDetails.trial.testId}/${userDetails.externalId}/${userDetails.userId}/${userDetails.allUserData}`,
          isIframe
        )
      );
    }
  }, [
    hearingTestDetails.trial?.stage,
    partyDetails.slug,
    hearingTestDetails.trial.testId,
    navigate,
    userDetails.externalId,
    userDetails.userId,
    userDetails.allUserData,
    isIframe,
  ]);

  const postTrial = (response: number) => {
    setLoading(true);

    HearingTestService.saveTrialAndContinue(
      hearingTestDetails.trial.id,
      response
    )
      .then((newTrial) => {
        setHearingTestDetails((prev) => ({ ...prev, trial: newTrial }));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (
    videoTestEnabled &&
    (videoTestDetails.rightEarPictures.length < 3 ||
      videoTestDetails.leftEarPictures.length < 3)
  ) {
    return <VideoTestNonIntegrated />;
  }

  return (
    <PageContainer>
      <HearingTestPanel
        loading={loading}
        trial={hearingTestDetails.trial}
        postTrial={postTrial}
        volumePercentage={volumePercentage}
      />
    </PageContainer>
  );
};
