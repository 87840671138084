import React, { useContext, useState } from "react";
import { PartyCodeDispatchContext } from "../../../contexts/PartyCodeContext";
import { PageContainer } from "../../shared/PageContainer";
import { AdminApi } from "../../hearing-test/services/admin-api";
import { EnabledTestsDispatchContext } from "../../../contexts/EnabledTestsContext";
import { useSetAtom } from "jotai";
import { cameraDetailsAtom } from "../../../atoms/atoms";
import CalibrationContext from "../../../contexts/CalibrationContext";
import defaultCalibration from "../../config/defaultCalibration";

type Props = {};

export const TestCodePage = (props: Props) => {
  const [testCode, setTestCode] = useState<string>("");
  const [testCodeError, setTestCodeError] = useState<string>("");
  const dispatchPartyDetails = useContext(PartyCodeDispatchContext);
  const dispatchEnabledTests = useContext(EnabledTestsDispatchContext);
  const setCameraDetails = useSetAtom(cameraDetailsAtom);
  const [, setCalibration] = useContext<any>(CalibrationContext);

  const submitTestCode = (e: React.FormEvent) => {
    e.preventDefault();

    if (testCode === "") {
      setTestCodeError("Please enter a test code");
    } else {
      setTestCodeError("");
      AdminApi.getCompanyByTestId(testCode)
        .then((company) => {
          if (company.slug) {
            const { slug, name, authToken, companyTestId, ...tests } = company;
            dispatchPartyDetails({
              type: "setClinic",
              payload: { slug, name },
            });
            sessionStorage.setItem("partyCode", testCode);

            dispatchEnabledTests({
              type: "setTests",
              payload: tests,
            });

            setCameraDetails({
              cameraName: company.cameraName,
            });

            if (company.slug.toLowerCase() !== "demo") {
              AdminApi.getDeviceCalibration(slug)
                .then((res) => {
                  if (res?.frequencies) {
                    setCalibration(res.frequencies);
                  }
                })
                .catch((err) => {
                  setCalibration(defaultCalibration().frequencies);
                });
            }
          } else {
            setTestCodeError("Invalid test code");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <PageContainer minHeight="220px">
      <form onSubmit={submitTestCode}>
        <div className="mb-7 text-left">
          <label
            htmlFor="demo-test-code"
            className="font-semibold mb-1 inline-block"
          >
            Test Code:
          </label>
          <br />
          <input
            type="text"
            className="border border-slate-200 p-4 rounded-md drop-shadow-sm w-full"
            id="demo-email"
            data-test="test-code"
            required
            onChange={(e) => setTestCode(e.target.value)}
            value={testCode}
          />
          {testCodeError && (
            <p className="text-[#ff0000]" data-test="test-code-error">
              {testCodeError}
            </p>
          )}
        </div>

        <button
          className="bg-blue text-white text-xl font-bold py-4 px-4 rounded-full drop-shadow-md ml-3 my-1 whitespace-nowrap"
          type="submit"
          data-test="find-test"
        >
          Find test
        </button>
      </form>
    </PageContainer>
  );
};
