import { HseV1Categorisation } from "../../../shared/models/client-categorisation";

export const buildDiagnostics = (result: HseV1Categorisation) => {
  if (result) {
    let resultText =
      "<div>Left Ear : <br />" +
      result.leftCategorisation.diagnostics +
      "<br /><br /><br />Right Ear : <br />" +
      result.rightCategorisation.diagnostics +
      "<br />Unilateral Hearing Loss : " +
      (result.unilateral === true ? "YES" : "NO") +
      "<br /><br /><br /></div>";
    return (
      <div
        id="diagnosticsText"
        dangerouslySetInnerHTML={{ __html: resultText }}
      />
    );
  }
};
