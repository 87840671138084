export interface ButtonProps {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  name?: string;
  dataTest?: string;
  size?: "small" | "medium" | "large";
  type?: "button" | "submit" | "reset";
}

const paddings = {
  small: `
  text-xs medium:text-sm
  tallAndSlim:py-1 tallAndSlim:px-1
  wideAndShort:py-1 wideAndShort:px-2
  medium:py-1 medium:px-3
  tallAndWide:py-2 tallAndWide:px-4
  `,
  medium: `
  text-sm medium:text-base
  tallAndSlim:py-2 tallAndSlim:px-2
  wideAndShort:py-2 wideAndShort:px-3
  medium:py-2 medium:px-4
  tallAndWide:py-3 tallAndWide:px-6`,
  large: `
  text-base medium:text-lg
  tallAndSlim:py-4 tallAndSlim:px-4
  wideAndShort:py-4 wideAndShort:px-6
  medium:py-4 medium:px-6
  tallAndWide:py-4 tallAndWidewide:px-8`,
};

export const Button = (props: ButtonProps) => {
  const base =
    "bg-blue text-white text-sm medium:text-xl font-bold " +
    paddings[props.size ?? "medium"] +
    " rounded-full drop-shadow-md whitespace-nowrap";
  const active =
    "hover:bg-blue-700 transition hover:hoverBlue duration-150 hover:text-blue duration-150";
  const disabled = "opacity-50 cursor-not-allowed";

  const handleClick = (e: any) => {
    if (props.onClick && !props.disabled) {
      props.onClick();
    }
  };

  return (
    <button
      type={props.type ?? "button"}
      disabled={props.disabled}
      className={`py-1 px-2 ${base} ${props.disabled ? disabled : active}`}
      onClick={handleClick}
      data-test={props.dataTest}
    >
      {props.text}
    </button>
  );
};
