import { HseV1Categorisation } from "../../shared/models/client-categorisation";
import { Trial } from "../../shared/models/trial";
import { axiosApiProvider } from "../../state/app-state";
import { appConfig } from "../../config/app-config";

export class HearingTestService {
  public static startNewTest = (
    externalId: string,
    partyCode: string,
    age: number,
    genderId: number,
    fullName: string | null = null,
    email: string | null = null,
    userId: string | null = null
  ): Promise<Trial> => {
    return axiosApiProvider()
      .post<Trial>(
        "/api/v1.0/hearingtest/start",
        {
          externalId,
          partyCode,
          age,
          gender: Number(genderId),
          fullName,
          email,
          userId,
        },
        { withCredentials: true }
      )
      .then((response) => response.data);
  };

  public static saveTrialAndContinue = (
    trialId: number,
    patientResponse: number
  ): Promise<Trial> => {
    return axiosApiProvider()
      .post<Trial>(
        `/api/v1.0/hearingtest/trial/${trialId}/${patientResponse}/saveandcontinue`,
        {},
        { withCredentials: true }
      )
      .then((response) => response.data);
  };

  public static checkPartyCode = (partyCode: string): Promise<boolean> => {
    return axiosApiProvider()
      .get<boolean>(`/api/v1.0/hearingtest/party/${partyCode}`, {
        withCredentials: true,
      })
      .then((response) => response.data);
  };

  public static checkTestCodeExist = (testCode: string): Promise<boolean> => {
    return axiosApiProvider()
      .post<boolean>(
        `/api/v1.0/hearingtest/${testCode}`,
        {},
        { withCredentials: true }
      )
      .then((response) => response.data);
  };

  public static getNewTestCode = (partyCode: string): Promise<string> => {
    return axiosApiProvider()
      .get<string>(`/api/v1.0/hearingtest/newTestCode/${partyCode}`, {
        withCredentials: true,
      })
      .then((response) => response.data);
  };

  public static getResultCategorisation = (
    externalId: string
  ): Promise<HseV1Categorisation> => {
    const config = {
      headers: {
        AuthGuid: appConfig().auth,
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    return axiosApiProvider()
      .get<HseV1Categorisation>(
        `/api/v1/hearingtest/categorisation/${externalId}`,
        config
      )
      .then((response) => response.data);
  };
}
