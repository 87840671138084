import { ReactChild, ReactChildren } from "react";
import { NavigationBar } from "../navigation/NavigationBar";

interface AppContainerProps {
  children: ReactChild | ReactChildren;
}

export const AppContainer = ({ children }: AppContainerProps) => {
  return (
    <div className="flex w-screen flex-grow flex-col mx-auto px-4 pb-16 overflow-auto">
      <NavigationBar />
      {children}
    </div>
  );
};
