import { useContext } from "react";
import IframeContext from "../../contexts/IframeContext";
import { setPath } from "../../utility/setPath";
import { HearingTestLatus } from "../../modules/hearing-test/components/HearingTestLatus";
import { ServerError } from "../../modules/error/ServerError";
import { LatusResult } from "../../modules/result/latus/LatusResult";
import { PartyCodeContext } from "../../contexts/PartyCodeContext";
import { Route, Routes } from "react-router-dom";

type LatusJourneyProps = {};

export const LatusJourney = (props: LatusJourneyProps) => {
  const isIframe = useContext(IframeContext);
  const partyDetails = useContext(PartyCodeContext);
  return (
    <div className="flex w-full h-full flex-col text-center">
      <Routes>
        <Route
          path={setPath(`occupational-health/latus/:sessionId`, isIframe)}
          element={<HearingTestLatus />}
        />

        <Route
          path={setPath(
            `result/latus/:testId/:externalId/:userId/:userDataParam`,
            isIframe
          )}
          element={<LatusResult />}
        />

        <Route
          path={setPath(`/${partyDetails.slug}/serverError`, isIframe)}
          element={<ServerError />}
        />

        <Route path="*" element={<div>Not Found...</div>} />
      </Routes>
    </div>
  );
};
