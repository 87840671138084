const channelsDataObject = () => {
  const labels = ["Low Freq.", "Medium Freq.", "High Freq."];

  return {
    leftData: {
      labels,
      datasets: [
        {
          label: "Intensity",
          data: [] as number[],
          backgroundColor: [] as string[],
        },
      ],
    },
    rightData: {
      labels,
      datasets: [
        {
          label: "Intensity",
          data: [] as number[],
          backgroundColor: [] as string[],
        },
      ],
    },
  };
};
export default channelsDataObject;
