import * as Tone from "tone";
import { AudioChannel } from "../../shared/models/audio-channel";
import { ToneContext } from "../../shared/models/tone-context";
import { Trial } from "../../shared/models/trial";

export class AudioService {
  public static readonly MaxGain: number = 0.05;
  public static readonly GainCalibrationFactor: number = 0.0001;

  public static readonly playOrientationCheck = (
    channel: AudioChannel,
    onStart: () => void,
    onEnd: () => void
  ): void => {
    onStart();
    const gain = new Tone.Gain(0, "gain");
    const merge = new Tone.Merge(2).toDestination();
    const osc = new Tone.Oscillator({
      frequency: 1000,
      type: "sine",
      onstop: onEnd,
    });

    gain.gain.setValueAtTime(AudioService.MaxGain, 0);
    osc.connect(gain);
    const silence = new Tone.BufferSource();

    if (channel === AudioChannel.right) {
      silence.connect(merge, 0, 0);
      gain.connect(merge, 0, 1);
    } else {
      silence.connect(merge, 0, 1);
      gain.connect(merge, 0, 0);
    }

    osc.start().stop("+1");
  };

  public static readonly findClosestFrequency = (keys: any, target: any) => {
    return keys.reduce((a: any, b: any) => {
      return Math.abs(b - target) < Math.abs(a - target) ? b : a;
    }, keys[0]);
  };

  public static readonly playTrial = (
    trial: Trial,
    onStart: () => void,
    onEnd: () => void,
    claibration: any
  ): void => {
    onStart();
    const tones: ToneContext[] = [];
    const gainCalibrationFactor =
      trial.device && trial.device.gainCalibrationFactor
        ? trial.device.gainCalibrationFactor
        : AudioService.GainCalibrationFactor;
    //channel 1 right
    //channel 2 left
    let channel = "right";
    if (trial.channel === 2) {
      channel = "left";
    }
    let frqs = trial.frequency;

    // console.log("Frequency: "+frqs)
    for (let level of trial.levels!) {
      if (frqs) {
        //When the frequency is not in the calibration list,
        //use the closest one
        if (!claibration[channel][frqs]) {
          let frqsKeys = Object.keys(claibration[channel]);

          // Use a function outside the loop to find the closest frequency
          const closestFrqs = AudioService.findClosestFrequency(frqsKeys, frqs);

          frqs = Number(closestFrqs);
        }

        // console.log("Index: "+test)
        // console.log("Decibel "+level)

        let frqsRecord = claibration[channel][frqs];
        let offset = frqsRecord[Object.keys(frqsRecord)[0]].offset;

        //console.log("Offset "+offset)

        if (offset.toString().startsWith("-", 0)) {
          offset = Number(offset.toString().slice(1));
          level = Number(level) - offset;
        } else {
          offset = Number(offset);
          level = Number(level) + offset;
        }

        //If level after offset is negative or 0, force level to 1
        if (level < 1) {
          level = 1;
        }

        //  console.log("Decibel after offet  "+level)
        // console.log("_______________")

        const toneContext: ToneContext = {
          oscillator: new Tone.Oscillator({
            frequency: trial.frequency,
            type: "sine",
          }),
          gain: new Tone.Gain(0, "gain"),
          level,
        };

        this.configureChannel(
          toneContext,
          trial.channel!,
          gainCalibrationFactor
        );
        tones.push(toneContext);
      }
    }

    // add callbacks to chain tones
    for (let i = 0; i < tones.length; i++) {
      if (i === tones.length - 1) {
        tones[i].oscillator.set({ onstop: onEnd });
      } else {
        tones[i].oscillator.set({
          onstop: () => {
            setTimeout(() => {
              this.playOscillator(tones[i + 1], trial.toneLengthMilliseconds);
            }, trial.stepGapMilliseconds);
          },
        });
      }
    }

    this.playOscillator(tones[0], trial.toneLengthMilliseconds);
  };

  static readonly playOscillator = (
    toneContext: ToneContext,
    toneLengthMilliseconds: number
  ) => {
    toneContext.oscillator
      .start()
      .stop(AudioService.toSecondsToneString(toneLengthMilliseconds));
  };

  static toSecondsToneString(milliseconds: number): string {
    return `+${milliseconds * 0.001}`;
  }

  static configureChannel(
    toneContext: ToneContext,
    channel: AudioChannel,
    gainCalibrationFactor: number
  ): ToneContext {
    const merge = new Tone.Merge(2).toDestination();
    toneContext.gain.gain.setValueAtTime(
      AudioService.decibelsToGain(toneContext.level) * gainCalibrationFactor,
      0
    );
    toneContext.oscillator.connect(toneContext.gain);
    const silence = new Tone.BufferSource();

    if (channel === AudioChannel.right) {
      toneContext.gain.connect(merge, 0, 1);
      silence.connect(merge, 0, 0);
    } else {
      toneContext.gain.connect(merge, 0, 0);
      silence.connect(merge, 0, 1);
    }

    return toneContext;
  }

  static rampUpAndDown(
    gain: Tone.Gain<"gain">,
    toneLengthSeconds: number,
    gainCalibrationFactor: number,
    level?: number
  ): void {
    const rampTimeSeconds = toneLengthSeconds / 10;
    gain.gain.rampTo(
      level
        ? AudioService.decibelsToGain(level) * gainCalibrationFactor
        : this.MaxGain,
      rampTimeSeconds
    );
    gain.gain.rampTo(0, rampTimeSeconds, toneLengthSeconds - rampTimeSeconds);
  }

  static decibelsToGain(decibelLevel: number): number {
    return Math.pow(10, decibelLevel / 20);
  }

  static runCalibrationTest(
    decibels: number,
    herz: number,
    audioChannel: AudioChannel,
    gain: number
  ): void {
    const toneContext: ToneContext = {
      oscillator: new Tone.Oscillator({ frequency: herz, type: "sine" }),
      gain: new Tone.Gain(0, "gain"),
      level: decibels,
    };

    this.configureChannel(toneContext, audioChannel, gain);
    this.playOscillator(toneContext, 3000);
  }
}
