import {
  axiosAdminApiProvider,
  axiosApiFastAGProvider,
  axiosApiLatusProvider,
} from "../../state/app-state";

export class UserService {
  //Get user info by appoiment id
  public static readonly getUserData = (
    appoimentId: string,
    Auth0Token: string
  ): Promise<any> => {
    const config = {
      headers: {
        Authorization: "Bearer " + Auth0Token,
        "Content-Type": "application/json",
      },
    };

    //GetForOnlineHearingCare
    return axiosApiLatusProvider()
      .get<any>(
        `/ScheduledHsAppointments/${appoimentId}/GetForOnlineHearingCare`,
        config
      )
      .then((response) => response.data);
  };

  public static readonly getNonIntegratedUserDetails = (
    email: string,
    companySlug: string
  ): Promise<any> => {
    return axiosAdminApiProvider()
      .get<any>(
        `/api/v1/get-user-id/${encodeURI(companySlug)}/${encodeURI(email)}`,
        {
          withCredentials: true,
        }
      )
      .then((response) => response.data);
  };

  public static readonly saveUserDetails = (
    email: string,
    companySlug: string,
    userId: string
  ): Promise<any> => {
    return axiosAdminApiProvider()
      .post<any>(
        `/api/v1/save-user-id`,
        { email, company: companySlug, user_id: userId },
        {
          withCredentials: true,
        }
      )
      .then((response) => response.data);
  };

  public static readonly saveResultFastAGP = (
    to: Array<string>
  ): Promise<boolean> => {
    return axiosApiFastAGProvider()
      .post<boolean>("/save-result-fast-agp/")
      .then((response) => response.data);
  };
}
