import { useContext } from "react";
import IframeContext from "../../contexts/IframeContext";
import { setPath } from "../../utility/setPath";
import { HearingTestNonIntegrated } from "../../modules/hearing-test/components/HearingTestNonIntegrated";
import { NonIntegratedResult } from "../../modules/result/non-integrated/NonIntegratedResult";
import { UserDetailsInput } from "../../modules/hearing-test/components/UserDetailsInput";
import { VideoTestNonIntegrated } from "../../modules/hearing-test/components/video-test/VideoTestNonIntegrated";
import { Redirect } from "../../utility/Redirect";
import { Route, Routes } from "react-router-dom";

type NonIntegratedJourneyProps = {};

export const NonIntegratedJourney = (props: NonIntegratedJourneyProps) => {
  const isIframe = useContext(IframeContext);

  return (
    <div className="flex w-full h-full flex-col text-center">
      <Routes>
        <Route
          path={setPath(`/occupational-health`, isIframe)}
          element={<UserDetailsInput />}
        />

        <Route
          path={setPath(`/occupational-health/redirect`, isIframe)}
          element={<Redirect />}
        />

        <Route
          path={setPath(`/occupational-health/video-test`, isIframe)}
          element={<VideoTestNonIntegrated />}
        />

        <Route
          path={setPath(
            `/result/:testId/:externalId/:userId/:userDataParam`,
            isIframe
          )}
          element={<NonIntegratedResult />}
        />

        <Route
          path={setPath(`/occupational-health/hearing-test`, isIframe)}
          element={<HearingTestNonIntegrated />}
        />

        <Route path="*" element={<div>Not Found...</div>} />
      </Routes>
    </div>
  );
};
