import html2canvas from "html2canvas";
import { downloadImage } from "./downloadImage";

export const exportDiagnostics = async (element: HTMLDivElement) => {
  await html2canvas(element, {
    onclone: function (clonedDoc) {
      let ourElement = clonedDoc.getElementById("diagnosticsMarker");
      if (ourElement != null) {
        ourElement.style.visibility = "visible";
      }
      let textElement = clonedDoc.getElementById("diagnosticsText");
      if (textElement != null) {
        const file = new Blob([textElement.innerText], { type: "text/plain" });
        let fileURL = URL.createObjectURL(file);
        downloadImage(fileURL, "DiagnosticsText.txt");
      }
    },
  });
};
