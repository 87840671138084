import { HseV1CategorisationResult } from "../../shared/models/hse-v1-categorisation-result";
import channelsDataObject from "./channelsDataObject";
import intensityBandsObject from "./intensityBandsObject";
import leftOptionsObject from "./leftOptionsObject";
import rightOptionsObject from "./rightOptionsObject";
import { Bar } from "react-chartjs-2";

export const leftData = channelsDataObject().leftData;
export const rightData = channelsDataObject().rightData;

const intensityBands = intensityBandsObject();
const intensityBandsRange =
  intensityBands.bands[0].range +
  intensityBands.bands[1].range +
  intensityBands.bands[2].range +
  intensityBands.bands[3].range +
  intensityBands.bands[4].range;

export const leftOptions = leftOptionsObject(intensityBandsRange);
export const rightOptions = rightOptionsObject(intensityBandsRange);

export const newTrafficLightResult = (
  result: HseV1CategorisationResult,
  ear: string
) => {
  if (result) {
    if (ear === "Left") {
      leftData.datasets[0].data = clampIntensities(result.bandedIntensity);
      leftData.datasets[0].backgroundColor = calculateIntensityColours(
        result.bandedIntensity
      );
      return (
        <div className="w-[40%]" data-test="bar-graph-container">
          <Bar
            style={{
              borderBottomStyle: "solid",
              borderBottomColor: "black",
              borderBottomWidth: "medium",
              borderLeftStyle: "solid",
              borderLeftColor: "black",
              borderLeftWidth: "medium",
            }}
            className="max-h-[100%]"
            options={leftOptions}
            data={leftData}
            height={(intensityBandsRange * 3).toString() + "px"}
          />
        </div>
      );
    } else {
      rightData.datasets[0].data = clampIntensities(result.bandedIntensity);
      rightData.datasets[0].backgroundColor = calculateIntensityColours(
        result.bandedIntensity
      );
      return (
        <div className="w-[40%]" data-test="bar-graph-container">
          <Bar
            style={{
              borderBottomStyle: "solid",
              borderBottomColor: "black",
              borderBottomWidth: "medium",
              borderRightStyle: "solid",
              borderRightColor: "black",
              borderRightWidth: "medium",
            }}
            className="max-h-[100%]"
            options={rightOptions}
            data={rightData}
            height={(intensityBandsRange * 3).toString() + "px"}
          />
        </div>
      );
    }
  }
};

const clampIntensities = (intensities: number[]) => {
  let results: number[] = [];
  if (intensities) {
    intensities.forEach((intensity) => {
      if (intensity > intensityBandsRange) {
        results.push(intensityBandsRange);
      } else {
        results.push(intensity);
      }
    });
  }
  return results;
};

const calculateIntensityColours = (results: number[]) => {
  let colours: string[] = [];
  if (results) {
    results.forEach((result) => {
      let runningTotal = result;
      let bandColour = "";
      intensityBands.bands.forEach((band) => {
        // I don't like this style, but apparently forEach cannot be broken out of.
        if (runningTotal < band.range && runningTotal >= 0) {
          bandColour = band.colour;
        }
        runningTotal -= band.range;
      });
      colours.push(bandColour);
    });
  }
  return colours;
};
