import { useContext } from "react";
import IframeContext from "../../contexts/IframeContext";
import { setPath } from "../../utility/setPath";
import { Button } from "../shared/Button";
import { PageContainer } from "../shared/PageContainer";
import {
  PartyCodeContext,
  PartyCodeDispatchContext,
} from "../../contexts/PartyCodeContext";
import { useNavigate } from "react-router-dom";

export const ServerError = (props: {}) => {
  const isIframe = useContext(IframeContext);
  const partyDetails = useContext(PartyCodeContext);
  const partyDetailsDispatch = useContext(PartyCodeDispatchContext);
  const navigate = useNavigate();

  const redirect = () => {
    partyDetailsDispatch({
      type: "setClinic",
      payload: { slug: "demo", name: "Demo" },
    });
    navigate(setPath(`/occupational-health/demo`, isIframe));
  };

  const button = partyDetails.slug ? (
    <Button text="Go back to the homepage" onClick={redirect} />
  ) : (
    <></>
  );

  return (
    <PageContainer>
      <section className="bg-primary py-[120px] relative z-10">
        <div className="container">
          <div className="flex -mx-4">
            <div className="w-full px-4">
              <div className="mx-auto max-w-[400px] text-center">
                <h2
                  className="
                  font-bold
                  mb-2
                  text-[50px]
                  sm:text-[80px]
                  md:text-[100px]
                  leading-none
                  "
                >
                  500
                </h2>
                <h4 className="font-semibold text-[22px] leading-tight mb-3">
                  Server error
                </h4>
                <p className="text-lg mb-8">
                  Please refresh this page or try again later
                </p>
                {button}
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageContainer>
  );
};
