import { useContext, useEffect, useState, useRef, useCallback } from "react";
import { HearingTestService } from "../../hearing-test/services/hearing-test-service";
import { HseV1Categorisation } from "../../shared/models/client-categorisation";
import { PageContainer } from "../../shared/PageContainer";
import { appConfig } from "../../config/app-config";
import { AudiogramChart } from "../functions/AudiogramChart";

import audiogramLineDataObject from "../functions/audiogramLineDataObject";
import { intensityKey } from "../functions/intensityKey";
import { TestTool } from "../functions/tests-tool/TestTool";

import { newTrafficLightResult } from "../functions/newTrafficLightResult";
import { reportOverallResult } from "../functions/reportOverallResult";
import { AdminApi } from "../../hearing-test/services/admin-api";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";

import html2canvas from "html2canvas";
import { PartyCodeContext } from "../../../contexts/PartyCodeContext";
import { useParams } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

// We should make this calculated from the table for ease of maintainance later.
//const intensityBandsRange = 30 + 20 + 30 + 20 + 30;
//const intensityBandsRange = 25 + 15 + 30 + 20 + 30;

// Data suggests bands itensity starts at -10, - will need to correlate this with the current system to check if this is how it's stored.
//const intensityBands = intensityBandsObject()

// Result Text.
export const audiogramLineData = audiogramLineDataObject();

export const audiogramConfig = {
  type: "line",
  data: audiogramLineData,
};

export const DemoResult = (props: {}) => {
  const [category, setCategory] = useState<HseV1Categorisation>();
  const [userData, setUserData] = useState<any>(null);
  const exportRef = useRef<HTMLDivElement>(null);

  const { testId, externalId, userId, userDataParam } = useParams();

  const devMode = appConfig().devMode;

  const partyDetails = useContext(PartyCodeContext);

  const saveDemoResults = useCallback(
    async (categorisation: any) => {
      let element = exportRef.current;
      if (element) {
        setTimeout(() => {
          html2canvas(element!).then((canvas: any) => {
            if (exportRef.current) {
              let imageBase64 = exportRef.current
                .getElementsByTagName("canvas")[0]
                .toDataURL("image/png", 0.5);
              if (imageBase64) {
                AdminApi.saveDemo(
                  categorisation,
                  userData,
                  imageBase64,
                  userId
                ).then((resultWP) => console.log(resultWP));
              }
            }
          });
        }, 1500);
      }
    },
    [userId, userData]
  );

  //Collect user data to save
  useEffect(() => {
    if (userDataParam) {
      let cryptUserData = atob(userDataParam);
      let arrayUserData = cryptUserData.split("£");

      let userEmail = arrayUserData[0];
      let gender = arrayUserData[1];
      let age = arrayUserData[2];

      setUserData({
        email: userEmail,
        age: age,
        gender: gender,
      });
    }
  }, [userDataParam]);

  useEffect(() => {
    if (externalId && partyDetails.slug && testId && userId && userData) {
      HearingTestService.getResultCategorisation(externalId).then(
        (responseCategory) => {
          setCategory(responseCategory);
          saveDemoResults(responseCategory);
        }
      );
    }
  }, [
    partyDetails.slug,
    externalId,
    testId,
    userId,
    saveDemoResults,
    userData,
  ]);

  return (
    <PageContainer>
      <div className="flex flex-col h-full">
        <div className="font-bold mb-2 text-4xl">Test Complete</div>
        <div>
          <p className="text-2xl mb-10">
            Thank you for completing the test. Below are your results
          </p>
        </div>
        <div className="flex flex-raw space-x-2">
          <div style={{ backgroundColor: "" }} className="w-[40%]">
            Left
          </div>
          <div
            style={{ backgroundColor: "" }}
            className="flex flex-col flex-auto"
          ></div>
          <div style={{ backgroundColor: "" }} className="w-[40%]">
            Right
          </div>
        </div>

        <div className="flex flex-row space-x-2">
          {newTrafficLightResult(category?.leftCategorisation!, "Left")}
          <div
            style={{ backgroundColor: "" }}
            className="flex flex-col flex-auto"
          >
            {intensityKey(category!)}
          </div>
          {newTrafficLightResult(category?.rightCategorisation!, "Right")}
        </div>

        <div className="flex flex-row space-x-2">
          <div
            style={{ backgroundColor: "" }}
            className="w-[40%] flex flex-row"
          >
            <div style={{ backgroundColor: "" }} className="w-[33%]">
              Low <br /> Freq.
            </div>
            <div style={{ backgroundColor: "" }} className="w-[33%]">
              Medium <br /> Freq.
            </div>
            <div style={{ backgroundColor: "" }} className="w-[33%]">
              High <br /> Freq.
            </div>
          </div>
          <div
            style={{ backgroundColor: "" }}
            className="flex flex-col flex-auto"
          ></div>
          <div
            style={{ backgroundColor: "" }}
            className="w-[40%] flex flex-row"
          >
            <div style={{ backgroundColor: "" }} className="w-[33%]">
              Low <br /> Freq.
            </div>
            <div style={{ backgroundColor: "" }} className="w-[33%]">
              Medium <br /> Freq.
            </div>
            <div style={{ backgroundColor: "" }} className="w-[33%]">
              High <br /> Freq.
            </div>
          </div>
        </div>

        <div className="self-end justify-self-end m-auto">
          {reportOverallResult(
            category?.leftCategorisation!,
            category?.rightCategorisation!
          )}
        </div>

        {devMode && <TestTool category={category} />}
        <div
          className="W-[100%] h-0 overflow-hidden"
          ref={exportRef}
          id="marker"
        >
          {category && (
            <AudiogramChart
              result={category}
              style={{ width: "600px", height: "500px" }}
            />
          )}
        </div>
      </div>
    </PageContainer>
  );
};
