import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import { Button } from "../../../shared/Button";
import { CameraStages } from "./VideoTestNonIntegrated";
import { useAtomValue, useSetAtom } from "jotai";
import { cameraDetailsAtom, videoTestAtom } from "../../../../atoms/atoms";
import MockImage from "./CI_Mock_Image.png";

interface MediaDevice {
  deviceId: string;
  kind: string;
  label: string;
  groupId: string;
}

interface Props {
  pictureNumber: (typeof CameraStages)[keyof typeof CameraStages];
  setPictureNumber: React.Dispatch<
    React.SetStateAction<(typeof CameraStages)[keyof typeof CameraStages]>
  >;
}

const CameraSelector: React.FC<Props> = ({
  pictureNumber,
  setPictureNumber,
}) => {
  const [devices, setDevices] = useState<MediaDevice[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<string | null>(null);
  const webcamRef = React.useRef<Webcam | null>(null);
  const [imgSrc, setImgSrc] = React.useState<string | null>();
  const setVideoTestResults = useSetAtom(videoTestAtom);
  const cameraDetails = useAtomValue(cameraDetailsAtom);

  const capture = React.useCallback(() => {
    if (process.env.REACT_APP_CI_TEST_ENV_FLAG) {
      setImgSrc(MockImage);
      return;
    }

    if (!webcamRef.current) return;
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  useEffect(() => {
    // Enumerate all video input devices
    navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
      console.log(mediaDevices);
      const videoDevices = mediaDevices.filter(
        (device) => device.kind === "videoinput"
      ) as MediaDevice[];

      setDevices(videoDevices);

      // Try to find an external camera from name
      let externalCamera = videoDevices.find(
        (device) =>
          cameraDetails.cameraName &&
          device.label.toLowerCase().includes(cameraDetails.cameraName)
      );

      // Mobile phones and tablets have facingMode property in
      // their built-in cameras, external devices don't
      if (!externalCamera) {
        externalCamera = videoDevices.find(
          (device) => !("facingMode" in device)
        );
      }

      // eliminate front and back cameras if none found by the name
      if (!externalCamera) {
        externalCamera = videoDevices.find(
          (device) =>
            !device.label.toLowerCase().includes("front") &&
            !device.label.toLowerCase().includes("back") &&
            !device.label.toLowerCase().includes("Integrated") &&
            !device.label.toLowerCase().includes("Intergrated")
        );
      }

      if (externalCamera) {
        setSelectedDevice(externalCamera.deviceId);
      }
    });
  }, [cameraDetails.cameraName]);

  useEffect(() => {
    const webcam = webcamRef.current;
    return () => {
      if (webcam) {
        const videoElem: HTMLVideoElement | null = webcam.video;
        if (videoElem?.srcObject) {
          const stream: MediaStream = videoElem.srcObject as MediaStream;
          stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
        }
      }
    };
  }, []);

  const videoConstraints = selectedDevice
    ? { deviceId: selectedDevice }
    : undefined;

  return (
    <div className="flex flex-col gap-2 mx-auto max-w-[40%]">
      {imgSrc ? (
        <div className="flex flex-col gap-2">
          <h2 className="font-bold">Picture Preview</h2>
          <p className="mb-4">
            Proceed if the picture looks correct, retake if not.
          </p>
          <img src={imgSrc} alt={"in ear shot"} />
          <Button
            text="Retake picture"
            dataTest="retake-picture"
            onClick={() => {
              setImgSrc(null);
            }}
          />
          <Button
            text="Use picture"
            dataTest="use-picture"
            onClick={() => {
              // set result atom
              switch (pictureNumber) {
                case CameraStages.leftEar1:
                case CameraStages.leftEar2:
                case CameraStages.leftEar3:
                  setVideoTestResults((prev) => ({
                    ...prev,
                    leftEarPictures: [...prev.leftEarPictures, imgSrc],
                  }));
                  break;
                case CameraStages.rightEar1:
                case CameraStages.rightEar2:
                case CameraStages.rightEar3:
                  setVideoTestResults((prev) => ({
                    ...prev,
                    rightEarPictures: [...prev.rightEarPictures, imgSrc],
                  }));
                  break;
              }

              setImgSrc(null);
              if (pictureNumber !== CameraStages.rightEar3) {
                setPictureNumber(
                  (pictureNumber +
                    1) as (typeof CameraStages)[keyof typeof CameraStages]
                );
              } else setPictureNumber(CameraStages.done);
            }}
          />
        </div>
      ) : (
        <>
          <h2 className="font-bold">In-ear camera gets automatically picked</h2>
          {/* <h3 className="font-bold">
            (Refresh and select the appropriate camera if it won't)
          </h3> */}
          {/* Selection is disabled for now, may re-enable in the future */}
          <select
            value={selectedDevice ?? undefined}
            onChange={(e) => setSelectedDevice(e.target.value)}
            className="border border-slate-200 p-2 rounded-md drop-shadow-sm w-full"
          >
            {devices.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label || `Camera ${device.deviceId}`}
              </option>
            ))}
          </select>

          <Webcam
            videoConstraints={videoConstraints}
            ref={webcamRef}
            audio={false}
            screenshotFormat="image/png"
          />
          <Button
            text="Take a picture"
            onClick={capture}
            dataTest="take-picture"
          />
        </>
      )}
    </div>
  );
};

export default CameraSelector;
