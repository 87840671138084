import React from "react";

type Props = {
  className?: string;
  dataTest?: string;
};

export const Text: React.FC<Props> = ({ children, className, dataTest }) => {
  return (
    <p
      className={`text-gray-700 text-sm medium:text-base tallAndWide:text-lg ${className}`}
      data-test={dataTest}
    >
      {children}
    </p>
  );
};
