const rightOptionsObject = (intensityBandsRange: any) => {
  return {
    responsive: true,
    plugins: {
      legend: {
        //      position: 'top' as const,
        display: false,
      },
      title: {
        display: false,
        //      text: "Right",
      },
    },
    scales: {
      y: {
        min: 0,
        max: intensityBandsRange,
        stepSize: 5,
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      x: {
        display: false,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
  };
};
export default rightOptionsObject;
