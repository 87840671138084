import { HseV1Categorisation } from "../../shared/models/client-categorisation";
import intensityBandsObject from "../functions/intensityBandsObject";

export const intensityKey = (result: HseV1Categorisation) => {
  if (result) {
    const intensityBands = intensityBandsObject();
    const intensityBandsRange =
      intensityBands.bands[0].range +
      intensityBands.bands[1].range +
      intensityBands.bands[2].range +
      intensityBands.bands[3].range +
      intensityBands.bands[4].range;

    // Calculate relative sizes of intensity bands, and produce marker blocks representing them.
    let keyBlocks: JSX.Element[] = [];
    let first = true;
    intensityBands.bands.forEach((band) => {
      let marginSize = 3; // first === true ? 3 : 0;
      if (first === true) {
        marginSize = 0;
      }

      let block = (
        <div
          className=""
          key={band.index}
          style={{
            backgroundColor: band.colour,
            color: "white",
            height:
              (
                (band.range / intensityBandsRange) * 100 -
                marginSize
              ).toString() + "%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <b> {band.name} </b>{" "}
        </div>
      );
      if (first === false) {
        let marginBlock = (
          <div
            key={"first_" + band.index}
            className=""
            style={{ backgroundColor: "white", height: "3%" }}
          ></div>
        );
        keyBlocks.push(marginBlock);
      }
      first = false;
      keyBlocks.push(block);
    });
    keyBlocks = keyBlocks.reverse();
    return <div style={{ height: "100%" }}> {keyBlocks} </div>;
  }
};
