import React, { useState } from "react";
import { Trial } from "../../shared/models/trial";
import { OrientationCheck } from "./test-stages/OrientationCheck";
import { RunTrial } from "./test-stages/RunTrial";

export interface HearingTrialProps {
  trial: Trial;
  postTrial: (patiantResponse: number) => void;
  loading: boolean;
  volumePercentage: number;
}

export const HearingTrial = (props: HearingTrialProps) => {
  const [subStage, setSubStage] = useState<number>(1);

  const handleStageComplete = () => {
    setSubStage(subStage + 1);
  };

  const selectedStage = () => {
    switch (subStage) {
      case 1: {
        return (
          <OrientationCheck
            trial={props.trial}
            onStageComplete={handleStageComplete}
            volumePercentage={props.volumePercentage!}
          />
        );
      }
      case 2: {
        return (
          <RunTrial
            loading={props.loading}
            trial={props.trial}
            onStageComplete={handleStageComplete}
            postTrial={props.postTrial}
          />
        );
      }
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-col h-full">
        <div className="text-sm font-bold medium:text-base medium:mb-2 tallAndWide:text-xl">
          Online hearing assessment
        </div>
        {selectedStage()}
      </div>
    </React.Fragment>
  );
};
