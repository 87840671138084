import configLocal from "../../appsettings.local.json";
import configDev from "../../appsettings.dev.json";
import configStaging from "../../appsettings.staging.json";
import configProd from "../../appsettings.prod.json";
import configCI from "../../appsettings.ci.json";

interface AppConfiguration {
  api: {
    baseURL: string;
    LatusURL: string;
    FastAGP: string;
  };
  latus: {
    auth0: {
      CLIENT_ID: string;
      DOMAIN: string;
      AUDIENCE: string;
    };
  };
  auth: string;
  adminApp: {
    baseURL: string;
  };
  devMode: boolean;
}

export const appConfig = (): AppConfiguration => {
  const host = window.location.host;

  if (process.env.REACT_APP_CI_TEST_ENV_FLAG) {
    return configCI as AppConfiguration;
  }

  //Local
  if (host.includes("localhost")) {
    return configLocal as AppConfiguration;
    // return configProd as AppConfiguration;
  }

  //Dev
  if (
    host === "wap-dev-app-001.azurewebsites.net" ||
    host === "fastag-dev.onlinehearingcare.co.uk" ||
    host === "dev-app.fastaghearing.com"
  ) {
    return configDev as AppConfiguration;
  }

  if (host.includes("staging")) {
    return configStaging as AppConfiguration;
  }

  //Prod
  if (
    host === "fastag.onlinehearingcare.co.uk" ||
    host === "app.fastaghearing.com"
  ) {
    return configProd as AppConfiguration;
  }

  throw Error("unexpected host");
};
