import { AudioChannel } from "./models/audio-channel";

export interface EarProps {
  channel?: AudioChannel;
}

export const Ear = (props: EarProps) => {
  return (
    <div className="flex flex-row align-middle justify-center w-full">
      <div
        className={
          "w-12 h-12 mr-6 medium:w-24 medium:h-24 medium:mr-12 tallAndWide:w-32 tallAndWide:h-32 tallAndWide:mr-16 bg-left-ear bg-contain bg-no-repeat " +
          (props.channel === AudioChannel.right ? "opacity-40" : "")
        }
      ></div>

      <div
        className={
          "w-12 h-12 medium:w-24 medium:h-24 tallAndWide:w-32 tallAndWide:h-32 bg-right-ear bg-contain bg-no-repeat " +
          (props.channel === AudioChannel.left ? "opacity-40" : "")
        }
      ></div>
    </div>
  );
};
